<template>
  <div>
    <TableLoader v-if="isLoading" />
    <CustomTable
      :fields="fields"
      :values="history || []"
      customPagination
      :paginationData="{ page: page, total: total, page_size: limit }"
      @update:page="(p) => onChangePage(p, limit)"
      @update:perPage="(l) => onChangePage(1, l)"
      @onSearch="onSearch"
      v-else
    >
      <template #user="{ data }">
        {{ data.user }}
      </template>
      <template #client="{ data }">
        {{ data.client }}
      </template>
      <template #project="{ data }">
        {{ data.project }}
      </template>
      <template #filename="{ data }">
        {{ data.filename }}
      </template>
      <template #datetime="{ data }">
        {{ data.datetime | parseUtcDate }}
      </template>
      <template #custom="{ data }">
        {{ data.custom }}
      </template>
      <template #status="{ data }">
        <span class="flex-center">
          <CIcon
            v-if="data.status == 'uploaded'"
            name="cil-check-alt"
            class="StatusUploaded"
            v-c-tooltip="$t('uploaded')"
          />
          <CIcon
            v-if="data.status == 'failed'"
            class="icon-size StatusFailed"
            name="xIcon"
            size="custom"
            v-c-tooltip="
              $t(data.error ? 'Failed: ' + data.error : 'unknown error')
            "
          />
          <CIcon
            v-if="data.status == 'in_progress'"
            name="cil-history"
            class="StatusInProgress"
            v-c-tooltip="$t('in progress...')"
          />
        </span>
      </template>
      <template #action="{ data }">
        <div class="flex-center">
          <CButton
            @click="
              () => {
                handleConfirmDelete(data);
                deletable_row = data;
              }
            "
            color="danger"
            class="action-btn"
            variant="outline"
            v-c-tooltip="$t('Delete data')"
            :disabled="!data?.can_remove"
          >
            <CIcon class="icon-size" name="trash" size="custom" />
          </CButton>
        </div>
      </template>
    </CustomTable>
    <ConfirmationModal
      :confirmDelete="confirmDelete"
      :name="this.currentRow.project"
      :postDeleteList="deleteData"
      @onHandleDelete="onHandleDelete"
    />
  </div>
</template>

<script>
import { ListsService } from "@/services/lists";
import { TemplatesService } from "@/services/templates";
import InvoiceTable from "../../components/InvoiceTable.vue";
import { CModal } from "@mz/coreui-vue";
import ConfirmationModal from "../../components/ConfirmationModal.vue";
import CustomTable from "../../components/CustomTable.vue";
import moment from "moment";
import TableLoader from "@/components/TableLoader.vue";

export default {
  name: "History",
  components: {
    TableLoader,
    InvoiceTable,
    CModal,
    ConfirmationModal,
    CustomTable,
  },
  data() {
    return {
      isLoading: true,
      isFetching: false,
      history: null,
      editable_row: null,
      confirmDelete: false,
      currentRow: {},
      deletable_row: null,
      fields: [
        {
          key: "user",
          field: "user",
          sortable: true,
        },
        {
          key: "client",
          field: "client",
          sortable: true,
        },
        {
          key: "project",
          field: "project",
          sortable: true,
        },
        {
          key: "filename",
          sortable: true,
          field: "filename",
          label: "File name",
        },
        {
          key: "datetime",
          sortable: true,
          field: "datetime",
          label: "Upload time",
        },
        {
          key: "custom",
          sortable: true,
          field: "custom",
          label: "Custom columns",
        },
        {
          key: "status",
          sortable: false,
          field: "status",
        },
        {
          key: "action",
        },
      ],
      total: 0,
      limit: 10,
      page: 1,
      filter: {},
    };
  },
  filters: {
    parseUtcDate(d) {
      return moment
        .utc(d, "YYYY-MM-DD HH:mm:ss")
        .local()
        .format("YYYY-MM-DD HH:mm:ss");
    },
  },

  methods: {
    onSearch(s) {
      this.filter = s;
      this.getHistory();
    },
    onChangePage(p, limit) {
      this.page = p;
      this.limit = limit;
      this.isLoading = true;
      this.getHistory();
    },
    async getHistory() {
      this.isFetching = true;
      var { data, res } = await ListsService.get_list_history({
        page: this.page,
        limit: this.limit,
        filter: this.filter,
      });
      if (res.status !== 200) {
        return alert("Please check your internet connetion or log in");
      }
      if (data.success) {
        this.history = data.data
          .map(({ id, ...keepAttrs }) => keepAttrs)
          .reverse();
        this.history = this.history.map((a) => {
          a.custom = a.custom.map((b) => b[0] + ": " + b[1]).join("; ");
          return a;
        });
        this.total = data.total;
      }
      this.isLoading = false;
      this.isFetching = false;
    },
    async handleConfirmDelete(row) {
      this.currentRow = row;
      this.confirmDelete = true;
    },

    async onHandleDelete({ event }) {
      this.confirmDelete = event;
    },

    async deleteData() {
      this.confirmDelete = false;
      this.history = this.history.filter(
        (a) =>
          !(
            a.client == this.deletable_row.client &&
            a.project == this.deletable_row.project &&
            a.upload_id == this.deletable_row.upload_id
          )
      );

      var { data, res } = await ListsService.delete_data({
        upload_id: this.deletable_row.upload_id,
        client: this.deletable_row.client,
        project: this.deletable_row.project,
      }).then(() => {
        this.confirmDelete = false;
      });

      if (res.status !== 200) {
        return alert("Please check your internet connetion or log in");
      }
    },
  },
  created() {
    this.isLoading = true;
    this.getHistory();
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  mounted: function () {
    this.timer = setInterval(() => {
      if (!this.isFetching) this.getHistory();
    }, 10000);
  },
};
</script>

<style scoped lang="scss">
.StatusUploaded {
  color: green;
}
.StatusFailed {
  color: red;
}
.StatusInProgress {
  color: gold;
}
</style>
